import { Box, Container } from '@chakra-ui/react';
import { NosPrestation, SectionHeader } from '../../../components/_index';
import AproposSection from './AproposSection';

export default function NosPrestationSection() {
    return (
        <Box bg={'white'}
            my={2}
        >
            <Container bg={'#EDF8FD'}
                borderRadius={'2xl'}
                boxShadow={'md'}
                maxW={'80%'}
                w={{ lg: '1050px' }}>
                <AproposSection />
                <SectionHeader sectionName={'Nos Prestations'} title={'Nos prestations d’entretien et de réparations'} />
                <NosPrestation />
            </Container>

        </Box>
    )
}