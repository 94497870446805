import {
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  VStack,
  ButtonGroup,
  Container,
  Flex,
  HStack,
  IconButton,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react'
import React from 'react';
import { Link } from 'react-router-dom';
import { RxHamburgerMenu } from 'react-icons/rx';
import logo from '../assets/images/dadycar-logo.png';

const SidebarContent = ({ onClick }) => (
  <VStack position={'relative'}>
    <Button onClick={onClick} w="100%">
      <Link to={'/services'}>Nos prestations</Link>
    </Button>
    <Button onClick={onClick} w="100%">
      <Link to={'/vendors'} >Liste Des Garages</Link>
    </Button>
    <Button onClick={onClick} w="100%">
      <Link to={'#'}>Dadycar Pros</Link>
    </Button>
    <Button onClick={onClick} w="100%">
      <Link to={'/drivers'}>Devenir chauffeur</Link>
    </Button>
    <Button onClick={onClick} w="100%">
      <Link to={'/vendor/register'}>Vous êtes garagiste ?</Link>
    </Button>
    <Button onClick={onClick} w="100%">
      <Link to={"/vendor/login"}>Se connecter</Link>
    </Button>
  </VStack>
)

const Sidebar = ({ isOpen, variant, onClose }) => {
  return variant === 'sidebar' ? (
    <Box
      position="fixed"
      left={0}
      p={5}
      w="200px"
      top={0}
      h="100%"
      bg="#dfdfdf"
    >
      <SidebarContent onClick={onClose} />
    </Box>
  ) : (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Image src={logo} w={'100px'} />
          </DrawerHeader>
          <DrawerBody>
            <SidebarContent onClick={onClose} />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}

export default function Navbar() {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const [indexPage, setIndexPage] = React.useState(window.location.pathname);
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => { }, [indexPage])

  const openToggle = () => (setIsOpen(!isOpen));

  return (
    <>
      <Sidebar isOpen={isOpen} onClose={openToggle} />
      <Box as="section" bg={'transparent'} position={'absolute'} w={'100%'} zIndex={100}>
        <Box as="nav" bg="bg-surface">
          <Container py={{ base: '4', lg: '3' }} mx={{ base: '0' }} maxW='100%'>
            <Flex justify={{ base: 'space-between', lg: 'space-evenly' }}>
              <Box px={'5px'} bg={"white"} borderRadius={'lg'}>
                <Link to={'/'} onClick={() => setIndexPage('/')}><Image src={logo} w={"99px"} /></Link>
              </Box>
              <HStack spacing="10" justify="space-between">
                {isDesktop ? (
                  <Flex justify="space-between" flex="1">
                    <ButtonGroup variant="link" spacing="4">
                      <Link to={'/services'}>
                        <Button style={indexPage === '/services' ? { textDecoration: 'underline' } : null}
                          onClick={() => setIndexPage('/services')} size={{ lg: 'sm' }} color={'white'}>
                          Nos prestations
                        </Button>
                      </Link>
                      <Link to={'/vendors'}>
                        <Button style={indexPage === '/vendors' ? { textDecoration: 'underline' } : null}
                          onClick={() => setIndexPage('/vendors')} size={{ lg: 'sm' }} color={'white'}>
                          Liste Des Garages
                        </Button>
                      </Link>
                      <Link to={'#'}>
                        <Button style={indexPage === '/dady-pros' ? { textDecoration: 'underline' } : null}
                          // onClick={() => setIndexPage('/dady-pros')}
                           size={{ lg: 'sm' }} color={'white'}>
                          Dadycar Pros
                        </Button>
                      </Link>
                      <Link to={'/drivers'}>
                        <Button style={indexPage === '/drivers' ? { textDecoration: 'underline' } : null}
                          onClick={() => setIndexPage('/drivers')} size={{ lg: 'sm' }} color={'white'}>
                          Devenir chauffeur
                        </Button>
                      </Link>
                      <Link to={'/vendor/register'} onClick={() => setIndexPage('/vendor/register')}>
                        <Button size={{ lg: 'sm' }} bg={"white"} borderRadius={'lg'} variant="ghost">
                          Vous êtes garagiste ?
                        </Button>
                      </Link>
                      <Link to={'/vendor/login'} onClick={() => setIndexPage('/vendor/login')}>
                        <Button size={{ lg: 'sm' }} bg={"white"} borderRadius={'lg'} variant="ghost">
                          Se connecter
                        </Button>
                      </Link>
                    </ButtonGroup>
                  </Flex>
                ) : (
                  <IconButton
                    variant="ghost"
                    color={'white'}
                    fontSize={'xxx-large'}
                    icon={<RxHamburgerMenu />}
                    aria-label="Open Menu"
                    onClick={openToggle}
                  />
                )}
              </HStack>
            </Flex>
          </Container>
        </Box>
      </Box>
    </>
  )
}