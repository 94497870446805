import HttpClient from '../services/HttpClient'

type FilterData = {
    versionId: number;
    lat: number;
    lng: number;
    radius: number;
    serviceId: number;
}
class Vendor {

    public static getVendor = async (vendorId: number) => {
        return HttpClient.get('/providers/' + vendorId);
    }

    public static getVendors = async () => {
        return await HttpClient.get('/providers');
    }

    public static getVendorsWithFilter = async (data: FilterData) => {
        return await HttpClient.get('/providers/filter?vehicle=' + data.versionId + '&lat=' + data.lat + '&lng=' + data.lng + '&radius=' + data.radius + '&service=' + data.serviceId);
    }
}

export default Vendor;