import HttpClient from '../services/HttpClient'

class Service {

    public static getService = async (serviceId: number) => {
        return HttpClient.get('/services/' + serviceId);
    }

    public static getServices = async () => {
        return await HttpClient.get('/services');
    }
}

export default Service;