import { Button } from '@chakra-ui/button'
import { FormControl, FormLabel } from '@chakra-ui/form-control'
import { Image } from '@chakra-ui/image'
import { Input } from '@chakra-ui/input'
import { Box, Flex, SimpleGrid, Stack, Text } from '@chakra-ui/layout'
import { Link } from 'react-router-dom'
import workerBg from '../../../assets/images/worker-bg.png'
import logo from '../../../assets/images/dadycar-logo.png'
import googleIcon from '../../../assets/images/google-2.png'

const LoginSection = () => {
    return (
        <Box>
            <SimpleGrid templateColumns={{ base: '2fr', lg: '2fr 2fr' }}>
                <Stack display={{ base: 'none', lg: 'unset' }}
                    backgroundImage={workerBg}
                    backgroundSize={{ lg: 'cover' }}
                    backgroundRepeat={'no-repeat'}
                    backgroundPosition={{ base: 'top', lg: 'center' }}>
                    {/* <Image src={workerBg} /> */}
                </Stack>
                <Stack py={{ base: 5, lg: 24, md: 20 }}>
                    <Box w={'100%'} >
                        <Flex
                            align={'center'}
                            justify={'center'}
                        >
                            <Stack w={{ base: '90%', lg: '60%' }} maxH={'90%'}>
                                <Box>
                                    <Stack align={'center'}>
                                        <Image src={logo} width={'100px'} />
                                    </Stack>
                                    <Stack spacing={4}>
                                        <FormControl id="email">
                                            <FormLabel>Adresse mail</FormLabel>
                                            <Input type="email" />
                                        </FormControl>
                                        <FormControl id="password">
                                            <FormLabel>Mot de passe</FormLabel>
                                            <Input type="password" />
                                        </FormControl>
                                        <Stack spacing={6}>
                                            <Button
                                                borderRadius={'lg'}
                                                bg={'#5393EC'}
                                                color={'white'}
                                                _hover={{
                                                    bg: 'blue.500',
                                                }}>
                                                Se connecter
                                            </Button>
                                            <Stack
                                                direction={{ base: 'column', sm: 'row' }}
                                                align={'start'}
                                                justify={'space-between'}>
                                                <Link color={'blue.400'}>Mot de passe oublié ?</Link>
                                            </Stack>
                                            <Stack>
                                                <Text textAlign={'center'}>Vous n’avez pas de compte ?</Text>
                                            </Stack>
                                            <Stack>
                                                <Link to={'/vendor/register'}>
                                                    <Button
                                                        w={'100%'}
                                                        borderRadius={'lg'}
                                                        bg={'#07D0C1'}
                                                        color={'white'}
                                                        _hover={{
                                                            bg: '#07D0C1',
                                                        }}>
                                                        S’enregistrer
                                                    </Button></Link>

                                            </Stack>
                                            <Stack>
                                                <Text textAlign={'center'}>ou</Text>
                                            </Stack>
                                            <Stack>
                                                <Button
                                                    borderRadius={'lg'}
                                                    bg={'white'}
                                                    color={'black'}
                                                    border={'1px solid grey'}
                                                    _hover={{
                                                        bg: 'white',
                                                    }}><Image src={googleIcon} mx={2} />
                                                    continuer avec google
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Flex>
                    </Box>
                </Stack>
            </SimpleGrid>
        </Box>
    )
}
export default LoginSection