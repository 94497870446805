import { Box, Center, Container, Heading, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import AproposPic from '../../../assets/images/man-driving.png'


export default function AboutSection() {
    return (
        <Container maxW={'80%'} w={{ lg: '1400px' }} mt={{ base: 10, lg: 20 }}>
            <Box py={4}>
                <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr' }} spacing={6}>
                    <Stack >
                        <Center>
                            <Box py={4}>
                                <Heading textAlign={{ base: 'center', lg: 'start' }} size={{ base: 'lg' }}>
                                    Pour avoir le privilège d’intégrer notre équipe
                                </Heading>
                                <Box py={3}>
                                    <Center display={{ base: 'unset', lg: 'none' }}>
                                        <Image my={6} src={AproposPic} />
                                    </Center>
                                    <Heading  size={{ base: 'md' }} py={3}>
                                        Vous devez être sous le statut auto-entrepreneur (ou EIRL) ou détenir une société immatriculée au RCS
                                    </Heading>
                                    <Text>Vous devez être sous le statut auto-entrepreneur (ou EIRL) ou détenir une société immatriculée au RCS</Text>
                                    <Box boxShadow={'md'} borderRadius={'2xl'} p={6}>
                                        <Text color={'#0F82EA'}>Vous n’êtes pas auto-entrepreneur</Text>
                                        <Text>
                                            Devenez-le en quelques minutes
                                            Les démarches sont simples et rapides avec des plateformes disponibles sur Google.

                                        </Text>
                                    </Box>
                                    <Box py={2}>
                                        <Heading size={{base:'sm',lg:'md'}} textAlign={{ base: 'center', lg: 'start' }}>Être âgé de plus de 21 ans et avoir plus de 3 ans de permis</Heading>
                                        <Text textAlign={{ base: 'center', lg: 'start' }} fontSize={{ base: 'smaller' }}>
                                            Vous devez être titulaire d’un permis B en cours de validité
                                        </Text>
                                    </Box>

                                    <Box py={2}>
                                        <Heading size={{base:'sm',lg:'md'}} textAlign={{ base: 'center', lg: 'start' }}>Avoir un smartphone</Heading>
                                        <Text textAlign={{ base: 'center', lg: 'start' }} fontSize={{ base: 'smaller' }}>
                                            Il est préférable que vous possédiez un smartphone plutôt récent.
                                        </Text>
                                    </Box>
                                </Box>

                            </Box>
                        </Center>
                    </Stack>
                    <Stack display={{ base: 'none', lg: 'unset' }}>
                        <Center>
                            <Image src={AproposPic} />
                        </Center>
                    </Stack>
                </SimpleGrid>
            </Box>
        </Container>
    )
}
