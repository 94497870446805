import { Box, Button, Center, Container, Image } from '@chakra-ui/react'
import React from 'react';
import slide from '../../../assets/images/Path-146.png'
import { SectionHeader } from '../../../components/_index';

export default function Avis() {
  return (
    <Container maxW={'80%'} w={{ lg: '1000px' }} py={5}>
      <SectionHeader sectionName={"TÉMOIGNAGE"} title={"Avis de nos clients."} />
      <Center py={5}>
        <Box w={{ lg: '60%' }}>
          <Image src={slide} />
        </Box>
      </Center>
      <Center>
        <Button
          borderRadius={'xl'}
          bg={'#5393EC'}
          color={'white'}
          w={{ lg: '60%' }}
        >Commander un Dadycar</Button>
      </Center>
    </Container>
  )
}
