import { BrowserRouter } from 'react-router-dom'
import RouterConfig from './routes/_RouterConfig'
import { Fonts, Theme } from './config/_index';
import { ChakraProvider } from '@chakra-ui/react';
import { Navbar, Footer } from '../src/components/_index'

function App() {
  return (
    <>
      <ChakraProvider theme={Theme}>
        <Fonts />
        <BrowserRouter>
          <Navbar />
          <RouterConfig />
          <Footer />
        </BrowserRouter>
      </ChakraProvider>
    </>
  );
}
export default App;