import HttpClient from '../services/HttpClient'

class Address {

    public static host = 'https://api-adresse.data.gouv.fr/search/?q=';

    public static getAddress = async (address: string) => {
        return HttpClient.get(address, Address.host);
    }
}

export default Address;