import React from "react";
import {
    Box,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Container,
    Heading,
    Image,
    SimpleGrid,
    Stack,
    Text,
} from "@chakra-ui/react";
import PriceTagIcon from '../../../assets/images/price-tag-2.png'
import CustomerSupportIcon from '../../../assets/images/customer-support.png'
import HomeIcon from '../../../assets/images/home.png'
import MessageIcon from '../../../assets/images/message-2.png'

export default function Service() {
    const [onHover, setOnHover] = React.useState();
    React.useEffect(() => { }, [onHover])
    function on(p) {
        setOnHover(p)
    }

    function out() {
        setOnHover(null)
    }
    return (
        <Container maxW={'80%'} px={0} py={5} w={{ lg: '1000px' }}>
            <Heading as={'h2'} py={'5'} textAlign={'center'}>PLUS BESOIN DE VOUS DÉPLACER NI DE VOUS MOBILISER POUR RÉPARER VOTE VOITURE</Heading>
            <Box py={10}>
                <SimpleGrid templateColumns={{ lg: '2fr 2fr 2fr 2fr' }} spacing={5}>
                    <Stack>
                        <Card px={3} py={5} borderRadius={'3xl'} onMouseOver={() => { on(1) }} onMouseOut={out}
                            align='center' h={'100%'} bg={'transparent'}
                            boxShadow={'none'} _hover={{ bg: 'white', boxShadow: 'md' }} >
                            <CardHeader py={4}>
                                <Box p={3} borderRadius={'lg'}
                                    bg={onHover === 1 ? '#DFF7FC' : 'transparrent'}
                                    boxShadow={onHover === 1 ? '0px 0px 15px rgba(0, 0, 0, 0.203922)' : null}>
                                    <Image w={'40px'} src={PriceTagIcon} />
                                </Box>
                            </CardHeader>
                            <CardBody p={0} >
                                <Heading size={'md'} textAlign={'center'}>Prix justes et transparents</Heading>
                            </CardBody>
                            <CardFooter py={2} >
                                <Text fontSize={'smaller'} textAlign={'center'}>Faites des économies. Nos
                                    experts en tarification nègocient
                                    des prix fixes et compétitifs
                                    jusqu’à 30% moins cher qu’un
                                    concessionnaire.</Text>
                            </CardFooter>
                        </Card>
                    </Stack>

                    <Stack>
                        <Card px={3} py={5} borderRadius={'3xl'} onMouseOver={() => { on(2) }} onMouseOut={out}
                            align='center' h={'100%'} bg={'transparent'}
                            boxShadow={'none'} _hover={{ bg: 'white', boxShadow: 'md' }} >
                            <CardHeader py={4}>
                                <Box p={3} borderRadius={'lg'}
                                    bg={onHover === 2 ? '#DFF7FC' : 'transparrent'}
                                    boxShadow={onHover === 2 ? '0px 0px 15px rgba(0, 0, 0, 0.203922)' : null} >
                                    <Image w={'40px'} src={CustomerSupportIcon} />
                                </Box>
                            </CardHeader>
                            <CardBody p={0}>
                                <Heading size={'md'} textAlign={'center'}>Garages triés sur le volet</Heading>
                            </CardBody>
                            <CardFooter py={2}>
                                <Text fontSize={'smaller'} textAlign={'center'}>Nous sélectionnons soigneusement nos garages partenaires.
                                    Ce sont des garages de confiance respectant notre charte Dadycar.
                                </Text>
                            </CardFooter>
                        </Card>
                    </Stack>

                    <Stack>
                        <Card px={3} py={5} borderRadius={'3xl'} onMouseOver={() => { on(3) }} onMouseOut={out}
                            align='center' h={'100%'} bg={'transparent'} boxShadow={'none'} _hover={{ bg: 'white', boxShadow: 'md' }}>
                            <CardHeader py={4}>
                                <Box p={3} borderRadius={'lg'}
                                    bg={onHover === 3 ? '#DFF7FC' : 'transparrent'}
                                    boxShadow={onHover === 3 ? '0px 0px 15px rgba(0, 0, 0, 0.203922)' : null}>
                                    <Image w={'40px'} src={HomeIcon} />
                                </Box>
                            </CardHeader>
                            <CardBody p={0}>
                                <Heading size={'md'} textAlign={'center'}>Collecte de vote voiture</Heading>
                            </CardBody>
                            <CardFooter py={2}>
                                <Text fontSize={'smaller'} textAlign={'center'}>Gagnez du temps. Nos chauffeurs Dadycar
                                    vote voiture
                                    chez vous ou au bureau et la
                                    ramènent aux horaires qui vous
                                    conviennent.</Text>
                            </CardFooter>
                        </Card>
                    </Stack>

                    <Stack>
                        <Card px={3} py={5} borderRadius={'3xl'} onMouseOver={() => { on(4) }} onMouseOut={out}
                            align='center' h={'100%'} bg={'transparent'} boxShadow={'none'} _hover={{ bg: 'white', boxShadow: 'md' }}>
                            <CardHeader py={4}>
                                <Box p={3} borderRadius={'lg'}
                                    bg={onHover === 4 ? '#DFF7FC' : 'transparrent'}
                                    boxShadow={onHover === 4 ? '0px 0px 15px rgba(0, 0, 0, 0.203922)' : null}>
                                    <Image w={'40px'} src={MessageIcon} />
                                </Box>
                            </CardHeader>
                            <CardBody p={0}>
                                <Heading size={'md'} textAlign={'center'}>Nos experts à vos côtés</Heading>
                            </CardBody>
                            <CardFooter py={2}>
                                <Text fontSize={'smaller'} textAlign={'center'}>Pas de mauvaise surprise! Un
                                    conseiller expert vous
                                    accompagne et s ‘assure que le
                                    devis est raisonnable et
                                    pertinent.</Text>
                            </CardFooter>
                        </Card>
                    </Stack>
                </SimpleGrid>
            </Box>
        </Container>
    )
}
