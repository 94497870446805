import { createSlice } from '@reduxjs/toolkit';

type VendorType = {
  id: number;
  name: string;
  address: string;
  email?: string;
  image?: string;
  reviews?: number;
  siret_number?: string;
  rcs_number?: string;
  phone_number?: number;
  lat?: number;
  lng?: number;
  services?: {
    id: number;
    name: string;
    description?: string;
  }[]
};

type SliceState = { list: VendorType[] | null, selected: VendorType | null };

const initialState: SliceState = { list: null, selected: null }

const vendorSlice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    updateList(state, action) {
      state.list = action.payload;
    },
    updateSelected(state, action) {
      state.selected = action.payload;
    },
    removeSelected(state) {
      state.selected = null;
    },
  },
});

export { type VendorType };
export const vendorActions = vendorSlice.actions;
export default vendorSlice.reducer;