import { AboutSection, AdventageSection, LoginSection, StepsSection , SliderSection, RegisterSection} from "./components/_index"

export default function DriverPage(props) {
  return (
    <>
    {props.action === "login" ?
      <LoginSection /> :
      <RegisterSection />}
      <AdventageSection />
      <AboutSection/>
      <StepsSection/>
      <SliderSection/>
    </>
  )
}
