import { useDispatch } from 'react-redux';
import { serviceActions, ServiceType } from '../store/slices/service-slice';
import { Service } from '../models/_index';

class ServiceViewModel {

    dispatch = useDispatch();

    public getServices = async () => {
        const response = await Service.getServices();
        if (response)
            this.dispatch(serviceActions.updateList(response.data));
    }

    public selectService = (service: ServiceType) => {
        this.dispatch(serviceActions.updateSelected(service));
    }

    public removeSelectedService = () => {
        this.dispatch(serviceActions.removeSelected());
    }
}

export default ServiceViewModel;