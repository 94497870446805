import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux'
import {
    vendorReducer,
    serviceReducer,
    addressReducer,
    markReducer,
    modelReducer,
    versionlReducer
} from './slices/_index';

const store = configureStore({
    reducer: {
        vendor: vendorReducer,
        service: serviceReducer,
        address: addressReducer,
        mark: markReducer,
        model: modelReducer,
        version: versionlReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch;
export default store;