import { Box, Center, Container, Heading, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import AproposPic from '../../../assets/images/worker-open-hood.png'

export default function AproposSection() {
  return (
    <Container maxW={'80%'} w={{ lg: '1400px' }} mt={{ base: 10, lg: 32 }}>
      <Box py={5}>
        <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr' }} spacing={6}>
          <Stack >
            <Center>
              <Box py={5}>
                <Heading textAlign={'center'} size={{ base: 'sm' }}>RÉPARER ET ENTRETENIR SON VÉHICULE
                  Obtenez des devis gratuits et des prestations au meilleur prix
                </Heading>
                <Text textAlign={'center'} fontSize={{ base: 'smaller' }}>
                  Avec Dadycar vous comparez et trouvez les meilleurs garages aux meilleurs prix. En quelques clics, Dadycar vous présente les meilleurs garages pour n’importe quelle prestation auto (révision, vidange ou changement de pneumatiques etc), avec des remises négociées exclusivement avec nos garages partenaires. Toutes les prestations d’entretien ou de réparation automobile sont disponibles, vous pouvez donc par exemple obtenir le meilleur prix pour une réparation de vos disques et plaquettes de frein ou pour un changement de votre kit de distribution.
                </Text>
              </Box>
            </Center>
          </Stack>
          <Stack>
            <Center>
              <Image src={AproposPic} />
            </Center>
          </Stack>
        </SimpleGrid>
      </Box>
    </Container>
  )
}
