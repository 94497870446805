import { Box } from '@chakra-ui/react'
import { BecomePartener } from '../../../components/_index'
import Avis from './Avis';
import dotBg from '../../../assets/images/dots-bg.png';

export default function PartenaireSection() {
    return (
        <Box backgroundImage={dotBg}
            backgroundSize={'cover'}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={'center'}>
            <Avis />
            <Box>
                <BecomePartener
                    title={'Devenez Chauffeur Dadycar'}
                    text={'Rejoignez-nous et obtenez des missions'} />
            </Box>
        </Box>
    )
}
