import { Image } from '@chakra-ui/image'
import {
    Center,
    SimpleGrid,
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Button,
    Heading,
    Text,
    Container,
    useColorModeValue,
    Select,
} from '@chakra-ui/react';
import loginBg from '../../../assets/images/login-section-bg.png'
import loginBg1 from '../../../assets/images/login-section-bg-md.png'
import loginBg2 from '../../../assets/images/login-section-bg-sm.png'
import logo from '../../../assets/images/dadycar-logo.png';

export default function RegisterSection() {
    return (
        <Box>
            <Box position={'absolute'} zIndex={-1} >
                <picture>
                    <source media="(min-width:48em)" srcSet={`${loginBg}`} />
                    <source media="(min-width:34em)" srcSet={`${loginBg1}`} />
                    <Image src={loginBg2} width="100vw"
                        backgroundRepeat={'no-repeat'}
                        backgroundSize={'contain'}
                        objectFit={'cover'} />
                </picture>
            </Box>
            <Container
                maxW={'100%'}
                py={{ base: 0, lg: 12 }}
                w={{ lg: '1440px' }}>
                <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr', md: '2fr 2fr' }}>
                    <Stack>
                        <Box pt={{ base: 24, lg: 32 }}>
                            <Box py={{ lg: 12, sm: 10 }}>
                                <Center>
                                    <Box px={5}>
                                        <Heading size={{ base: 'lg', lg: 'xl' }} color={'white'} textAlign={{ base: 'center', lg: 'start', md: 'start' }}>Devenez Chauffeur Dadycar</Heading>
                                        <br />
                                        <Text fontSize={{ base: 'md', lg: '2xl' }} color={'white'} textAlign={{ base: 'center', lg: 'start', md: 'start' }}>Convoyage de véhicules de particuliers et d’entreprises</Text>
                                        <br />
                                        <Text fontSize={{ base: 'md', lg: '2xl' }} color={'white'} textAlign={{ base: 'center', lg: 'start', md: 'start' }}>Revenus complémentaires</Text>
                                        <br />
                                        <Text fontSize={{ base: 'md', lg: '2xl' }} color={'white'} textAlign={{ base: 'center', lg: 'start', md: 'start' }}>Flexibilité des horaires</Text>
                                        <Text fontSize={{ base: 'md', lg: '2xl' }} textAlign={{ base: 'center', lg: 'start', md: 'start' }} color={'white'}>Clients réputés</Text>
                                    </Box>
                                </Center>
                            </Box>
                        </Box>
                    </Stack>
                    <Stack pt={{ base: 5, lg: 20, md: 20 }}>
                        <Box w={'100%'}>
                            <Flex
                                align={'center'}
                                justify={'center'}
                            >
                                <Stack w={{ base: '90%', lg: '60%' }} maxH={'90%'}>
                                    <Box
                                        borderRadius={'3xl'}
                                        bg={useColorModeValue('white', 'gray.700')}
                                        boxShadow={'md'}
                                        p={8}
                                        w={'100%'}>
                                        <Stack align={'center'}>
                                            <Image src={logo} width={'100px'} />
                                        </Stack>
                                        <Stack spacing={4}>
                                            <Flex >
                                                <FormControl pr={2} id="name">
                                                    <FormLabel>Nom</FormLabel>
                                                    <Input borderRadius={'2xl'} type="text" />
                                                </FormControl>
                                                <FormControl id="name">
                                                    <FormLabel>Prénom</FormLabel>
                                                    <Input borderRadius={'2xl'} type="text" />
                                                </FormControl>
                                            </Flex>
                                            <FormControl id="email">
                                                <FormLabel>Adresse mail</FormLabel>
                                                <Input borderRadius={'2xl'} type="email" />
                                            </FormControl>
                                            <FormControl id="select">
                                                <FormLabel>Êtes-vous auto-entrepreneur ou gérant d’une société ?</FormLabel>
                                                <Select borderRadius={'2xl'}>
                                                    
                                                </Select>
                                            </FormControl>
                                            <FormControl id="password">
                                                <FormLabel>Permis de conduire / depuis combien de temps ?</FormLabel>
                                                <Input borderRadius={'2xl'} type="text" />
                                            </FormControl>
                                            <FormControl id="select">
                                                <FormLabel>Que recherchez-vous ?</FormLabel>
                                                <Select borderRadius={'2xl'}>
                                                    
                                                </Select>
                                            </FormControl>
                                            <Stack>
                                                <Button
                                                    borderRadius={'lg'}
                                                    bg={'#5393EC'}
                                                    color={'white'}
                                                    _hover={{
                                                        bg: 'blue.500',
                                                    }}>
                                                    Se connecter
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Flex>
                        </Box>
                    </Stack>
                </SimpleGrid>
            </Container>


        </Box>
    )
}
