import { AboutVendors, BookingSection } from './components/_index'

export default function VendorsPage(props) {

  return (
    <>
      <BookingSection />
      <AboutVendors/>
    </>
  )
}
