import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Image, Select, Stack } from "@chakra-ui/react";
import prestationIcon from '../../../assets/images/Icon-metro-local-service.png';
import ServiceViewModel from '../../../view-models/ServiceViewModel';
import { RootState } from '../../../store';

const SelectService = () => {
    const serviceViewModel = new ServiceViewModel();
    useEffect(() => {
        serviceViewModel.getServices();
    }, []);

    const services = useSelector((state: RootState) => state.service.list);
    const selectedService = useSelector((state: RootState) => state.service.selected);

    const onChaneHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.currentTarget.value) {
            serviceViewModel.selectService({ id: +event.currentTarget.value, name: event.target.options[event.target.selectedIndex].textContent! });
        }
        else
            serviceViewModel.removeSelectedService();
    }

    return (
        <Stack bg={'#E8EAF1'} direction={'row'} m={'2'} align={'center'} borderRadius={'2xl'} px={4} py={1}>
            <Image display={{ base: 'block', lg: 'none' }} h={'20px'} src={prestationIcon} />
            <Select
                border='none'
                outline='none'
                placeholder='Votre Prestation'
                onChange={onChaneHandler}
                value={selectedService?.id}
            >
                {services && services.map((service) => {
                    return <option key={service['id']} value={service['id']}>{service['name']}</option>
                })}
            </Select>
        </Stack>
    );
}

export default SelectService;