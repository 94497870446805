import { Box, Center, Checkbox, Heading, List, ListItem, SimpleGrid, Stack, Text } from '@chakra-ui/react'

export default function SearchSection() {

    return (
        <Box py={10} bg={'#F6FAFC'} mt={{ base: 10, lg: 32 }}>
            <Box mx={20} py={10}>
                <Text textAlign={'center'}>Retrouvez les meilleurs garages auto à Paris 01 pour l’entretien auto courant : révision, plaquettes de frein, kit de distribution, décalaminage, embrayage, parallélisme, et la réparation de votre voiture quel que soit la marque et le modèle du véhicule. Trouvez le garage auto le moins cher et comparez rapidement les prix, tarifs et forfaits des garages grâce au devis instantané et la réservation de votre rendez-vous directement en ligne en quelques clics.</Text>
            </Box>
            <Box my={5} h={{ lg: '180px' }} bg={{ base: 'transparent', lg: 'white', md: 'white' }} boxShadow={{ lg: 'md', md: 'md' }}>
                <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr 2fr', md: '2fr 2fr 2fr' }} px={{ base: 6, lg: 0 }} spacing={{ base: 6, lg: 0 }}>
                    <Stack px={7} boxShadow={{ base: 'md', lg: 'none', md: 'none' }} bg={{ base: 'white', lg: 'transparent', md: 'transparent' }} borderRadius={{ base: '2xl', lg: 'none', md: 'none' }}>
                        <Center justifyContent={{ base: 'center', md: 'center' }}>
                            <Box py={7}>
                                <Heading as={'h6'} size={'sm'}>FILTRER PAR RÉSEAUX DE GARAGE</Heading>
                                <Box overflowY={'scroll'} h={'100px'} w={'100%'}>
                                    <List>
                                        {['1-2-3-Autoservice', 'Ad-Expert', 'Auto-Factory', 'Carrosserie-Ad'].map((item, idx) => (
                                            <ListItem key={idx}><Checkbox colorScheme={'blue'}>{item}</Checkbox></ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </Box>
                        </Center>
                    </Stack>

                    <Stack px={7} boxShadow={{ base: 'md', lg: 'none', md: 'none' }} bg={{ base: 'white', lg: 'transparent', md: 'transparent' }} borderRadius={{ base: '2xl', lg: 'none', md: 'none' }}>
                        <Center justifyContent={{ base: 'center', md: 'center' }}>
                            <Box py={7}>
                                <Heading as={'h6'} size={'sm'}>SERVICES +</Heading>
                                <Box overflowY={'scroll'} h={'100px'} w={'100%'} _css={{ '&::-webkit-scrollbar': { width: '4px', }, '&::-webkit-scrollbar-track': { width: '6px', }, '&::-webkit-scrollbar-thumb': { background: '#1385EB', borderRadius: '24px', }, }}>
                                    <List>
                                        {['Géométrie des trains', 'Facilités de paiement', 'Espace attente', 'Carrosserie-Ad',
                                            '1-2-3-Autoservice', 'Ad-Expert', 'Auto-Factory', 'Carrosserie-Ad'].map((item, idx) => (
                                                <ListItem key={idx}><Checkbox colorScheme={'blue'}>{item}</Checkbox></ListItem>
                                            ))}
                                    </List>
                                </Box>
                            </Box>
                        </Center>
                    </Stack>

                    <Stack px={7} boxShadow={{ base: 'md', lg: 'none', md: 'none' }} bg={{ base: 'white', lg: 'transparent', md: 'transparent' }} borderRadius={{ base: '2xl', lg: 'none', md: 'none' }}>
                        <Center justifyContent={{ base: 'center', md: 'center' }}>
                            <Box py={7}>
                                <Heading as={'h6'} size={'sm'}>RAYON DE RECHERCHE</Heading>
                                <Box overflowY={'scroll'} h={'100px'} w={'100%'}>
                                    <List>
                                        {[1, 2, 3, 4, 5, 6, 7].map((item, idx) => (
                                            <ListItem key={idx}><Checkbox colorScheme={'blue'}>{item * 5}km</Checkbox></ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </Box>
                        </Center>
                    </Stack>
                </SimpleGrid>
            </Box>
        </Box>
    )
}
