import { Box, Heading, Text } from '@chakra-ui/react'

export default function SectionHeader(props) {
    return (
        <Box py={12}>
            {props.sectionName ?
                <Text textAlign={'center'} color={'#1385EB'}>{props.sectionName}</Text>
                : null}

            <Heading textAlign={'center'}>{props.title ? props.title : 'Your Title'}</Heading>
        </Box>
    )
}
