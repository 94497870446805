import { AboutSection, HelpSection, InformationSection, LoginSection, PartenerSection, RegisterSection, ServicesSection } from "./components/_index";

export default function RegisterVendorPage(props) {
  return (
    <>
      {props.action === "login" ?
      <LoginSection /> :
      <RegisterSection />}
      <InformationSection />
      <ServicesSection />
      <AboutSection />
      <HelpSection />
      <PartenerSection />
    </>
  )
}
