import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  Input,
  useColorModeValue,
  Button,
  Image,
  Center,
} from '@chakra-ui/react';
import contactBg from '../assets/images/contact-section.png';
import logo from '../assets/images/dadycar-logo.png';
import contactBg2 from '../assets/images/Group-1081.png';
import posIcon from '../assets/images/Icon-material-location-on2.png';
import phoneIcon from '../assets/images/Icon-awesome-phone-alt.png';
import mailIcon from '../assets/images/Icon-ionic-ios-mail.png';
import fbIcon from '../assets/images/Icon-awesome-facebook-f.png';
import twitterIcon from '../assets/images/Icon-awesome-twitter.png';
import igIcon from '../assets/images/Icon-awesome-instagram.png';
import youtubeIcon from '../assets/images/Icon-awesome-youtube.png';
import linkedinIcon from '../assets/images/Icon-awesome-linkedin.png';


const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function Footer() {
  return (
    <>
      <Box position={'relative'} >
        <Box position={'absolute'} zIndex={-1} w={'100%'}>
          <picture>
            <source media="(min-width:768px)" srcSet={`${contactBg}`} />
            <Image src={contactBg2} width="100%" />
          </picture>
        </Box>
        <SimpleGrid px={4} py={{ lg: '10%', md: '10%', base: 10 }} templateColumns={{ sm: '1fr', md: '2fr 2fr 2fr 2fr', lg: '2fr 2fr 2fr 2fr' }} spacingY={7}>
          <Stack>
            <Center>
              <Image src={logo} w={{ lg: '200px', base: '100px' }} />
            </Center>
          </Stack>
          <Stack justify={'center'}>
            <Center>
              <Box display={{ lg: 'flex', md: 'flex', base: 'block' }}>
                <Box px={2} display={'flex'} justifyContent={'center'}>
                  <Image src={posIcon} h={'30px'} />
                </Box>

                <Text color={'white'}>36 Rue Raspail, 92300
                  Levallois-Perret</Text>
              </Box>

            </Center>
          </Stack>
          <Stack justify={'center'}>
            <Center>
              <Box display={{ lg: 'flex', md: 'flex', base: 'block' }}>
                <Box px={2} display={'flex'} justifyContent={'center'}>
                  <Image src={phoneIcon} h={'30px'} />
                </Box>
                <Text color={'white'}>01 76 44 05 11</Text>
              </Box>
            </Center>
          </Stack>
          <Stack justify={'center'}>
            <Center>
              <Box display={{ lg: 'flex', md: 'flex', base: 'block' }}>
                <Box px={2} display={'flex'} justifyContent={'center'}>
                  <Image src={mailIcon} h={'30px'} />
                </Box>
                <Box>
                  <Text color={'white'}>contact@dadycar.fr</Text>
                  <Text color={'white'}>support@dadycar.fr</Text>
                </Box>
              </Box>
            </Center>
          </Stack>
        </SimpleGrid>
      </Box>
      <Box
        bg={'white'}
        color={useColorModeValue('gray.700', 'gray.200')}>
        <Container as={Stack} maxW={'6xl'} py={10}>
          <SimpleGrid
            templateColumns={{ sm: '1fr 1fr', md: '2fr 2fr 2fr' }}
            spacing={8}>

            <Stack align={'flex-start'}>
              <ListHeader>DADYCAR.FR</ListHeader>
              <Link href={'#'}>Qui sommes-nous.</Link>
              <Link href={'#'}>CGU et Mentions Légales.</Link>
              <Link href={'#'}>RÉVISION ET VIDANGE.</Link>
              <Link href={'#'}>Dadycar dans les médias.</Link>
              <Link href={'#'}>FAQs.</Link>
              <Link href={'#'}>Nous contacter.</Link>
              <Link href={'#'}>SUSPENSIONS ET PNEUMATIQUE.</Link>
              <Link href={'#'}>CONTRÔLE TECHNIQUE.</Link>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader></ListHeader>
              <br />
              <Link href={'#'}>Partenaires.</Link>
              <Link href={'#'}>Espace Pro Garagistes.</Link>
              <Link href={'#'}>Protection des données personnelles.</Link>
              <Link href={'#'}>KIT DE DISTRIBUTION.</Link>
              <Link href={'#'}>DISQUES ET PLAQUETTES DE FREIN.</Link>
              <Link href={'#'}>Kit d’embrayage.</Link>
              <Link href={'#'}>CARROSSERIE ET VISION.</Link>
              <Link href={'#'}>DIAGNOSTIC ET RECHERCHE DE PANNE.</Link>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>INSCRIVEZ-VOUS À LA NEWSLETTER</ListHeader>
              <Stack direction={'row'}>
                <Input
                  placeholder={'Adresse Email'}
                  bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
                  border={0}
                  borderRadius={'xl'}
                  _focus={{
                    bg: 'whiteAlpha.300',
                  }}
                />
                <Button
                  bg={'#5393EC'}
                  color={useColorModeValue('white', 'gray.800')}
                  borderRadius={'xl'}
                  fontSize={'smaller'}
                  _hover={{
                    bg: '#5393EC',
                  }}
                  aria-label="Subscribe"
                >
                  Envoyer
                </Button>

              </Stack>
              <Box>
                <Stack px={6} spacing={{ base: 10, lg: 6 }}>
                  <Stack direction={'row'} spacing={6}>
                    <Link label={'Facebook'} href={'#'}>
                      <Image h={'20px'} src={fbIcon} />
                    </Link>
                    <Link label={'Twitter'} href={'#'}>
                      <Image h={'20px'} src={twitterIcon} />
                    </Link>
                    <Link label={'Youtube'} href={'#'}>
                      <Image h={'20px'} src={youtubeIcon} />
                    </Link>
                    <Link label={'LinkedIn'} href={'#'}>
                      <Image h={'20px'} src={linkedinIcon} />
                    </Link>
                    <Link label={'Instagram'} href={'#'}>
                      <Image h={'20px'} src={igIcon} />
                    </Link>
                  </Stack>
                </Stack>
              </Box>
              <Box>
                <Stack align={'flex-start'}>
                  <Link href={'#'}>Devenir chauffeur</Link>
                  <Link href={'#'}>Vous êtes garagiste ?</Link>
                  <Link href={'#'}>Rejoignez-nous </Link>
                  <Link href={'#'}>Contactez-nous</Link>
                </Stack>
              </Box>
            </Stack>
          </SimpleGrid>
        </Container>
      </Box>
      <Center bg={'linear-gradient(270deg, #00DCBB 1.75%, #006AFC 100%)'} h={10}>
        <Text textAlign={'center'} color={'white'} fontSize={{ base: 'xs', md: 'sm' }}>© Copyright Dadycar 2022. All right reserved. Développé par Codemade</Text>
      </Center>
    </>
  );
}