import { Box, Button, Heading, Image, Input, Stack, StackDivider, Text, VStack } from "@chakra-ui/react";
import { useState, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import carIcon from '../../../assets/images/Icon-ionic-ios-car.png';
import { RootState } from "../../../store";
import { MarkViewModel, ModelViewModel, VersionViewModel } from "../../../view-models/_index";

const SelectVehicle: React.FC = () => {
    const markViewModel = new MarkViewModel();
    const marks = useSelector((state: RootState) => state.mark.list);
    const selectedMark = useSelector((state: RootState) => state.mark.selected);

    const modelViewModel = new ModelViewModel();
    const models = useSelector((state: RootState) => state.model.list);
    const selectedModel = useSelector((state: RootState) => state.model.selected);

    const versionViewModel = new VersionViewModel();
    const versions = useSelector((state: RootState) => state.version.list);
    const selectedVersion = useSelector((state: RootState) => state.version.selected);

    const [inputFocus, setInputFocus] = useState(false);
    const [option, setOption] = useState('marques');
    const [options, setOptions] = useState(marks);
    const [reasultSearch, setReasultSearch] = useState(marks);

    const wrapperRef = useRef(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const searchRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (selectedMark && selectedModel && selectedVersion)
            inputRef.current!.value = selectedMark.name + ' /' + selectedModel.name + ' /' + selectedVersion.name;
        markViewModel.getMarks();
    }, []);

    useEffect(() => {
        setOption('marks');
        setOptions(marks);
        setReasultSearch(marks);
    }, [marks]);

    useEffect(() => {
        setOption('models');
        setOptions(models);
        setReasultSearch(models);
    }, [models]);

    useEffect(() => {
        setOption('versions');
        setOptions(versions);
        setReasultSearch(versions);
    }, [versions]);

    useOutsideAlerter(wrapperRef, setInputFocus);

    const onfocusHandler = () => {
        setInputFocus(true);
    }

    const onchangeInputHandler = (event: React.FormEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value.toLowerCase();
        if (value.length > 0) {
            const res = options!.filter(obj => Object.values(obj).some(val => val?.toString().toLowerCase().includes(value)));
            setReasultSearch(res);
        } else {
            setReasultSearch(options);
        }
    }

    const onClickHandler = (id: number, name: string) => {
        switch (option) {
            case 'marks':
                modelViewModel.getMarkModels(id);
                markViewModel.selectMark({ id: id, name: name })
                inputRef.current!.value = name + ' /';
                searchRef.current!.value = '';
                break;
            case 'models':
                versionViewModel.getModelVersions(id);
                modelViewModel.selectModel({ id: id, name: name })
                inputRef.current!.value = inputRef.current!.value + name + ' /';
                searchRef.current!.value = '';
                break;
            case 'versions':
                versionViewModel.selectVersion({ id: id, name: name })
                inputRef.current!.value = inputRef.current!.value + name + ' /';
                searchRef.current!.value = '';
                setOption('summary');
                break;
        }
    }

    const resetSearch = () => {
        setOption('marks');
        setOptions(marks);
        setReasultSearch(marks);
        markViewModel.removeSelectedMark();
        modelViewModel.removeSelectedModel();
        versionViewModel.removeSelectedVersion();
        inputRef.current!.value = '';
        searchRef.current!.value = '';
    }

    return (
        <>
            <Box ref={wrapperRef}>
                <Stack bg={'#E8EAF1'} direction={'row'} m={'2'} align={'center'} borderRadius={'2xl'} pl={4} py={1}>
                    <Image h={'20px'} src={carIcon} />
                    <Input
                        bg={'transparent'}
                        border={'none'}
                        outline={'none'}
                        placeholder={'Vehicule'}
                        onFocus={onfocusHandler}
                        ref={inputRef}
                        readOnly
                    />
                    {
                        selectedMark ?
                            <Button aria-label='Reset' _hover={{ bg: 'null' }} variant='link' onClick={resetSearch}>x</Button>
                            : null
                    }
                </Stack>
                {
                    inputFocus ?
                        <VStack
                            width={"72"}
                            rounded={12}
                            boxShadow={'2xl'}
                            border='1px'
                            borderColor='gray.200'
                            position={'absolute'}
                            zIndex={'999'}
                            bg='white'
                            divider={<StackDivider borderColor='gray.200' />}
                            align='stretch'
                            pb={2}
                        >
                            {option === 'summary' ?
                                <VStack align='stretch' px={4}>
                                    <Heading as='h3' size='sm' py={4} color={'#1e90ff'}>
                                        Le résumé
                                    </Heading>
                                    <Text>Marque: {selectedMark?.name}</Text>
                                    <Text>Model: {selectedModel?.name}</Text>
                                    <Text>Version: {selectedVersion?.name}</Text>
                                </VStack>
                                :
                                <Box px={4}>
                                    <Heading as='h3' size='sm' py={4} color={'#1e90ff'}>
                                        {renderSummaryTitle(option)}
                                    </Heading>
                                    <Input ref={searchRef} placeholder={'Chercher...'} bg={'#E8EAF1'} onChange={onchangeInputHandler} />
                                    <VStack
                                        align='stretch'
                                        divider={<StackDivider borderColor='gray.200' />}
                                        maxH={'300px'}
                                        overflow={'auto'}
                                        mt={'4'}
                                    >
                                        {reasultSearch && reasultSearch.map((each) => {
                                            return (
                                                <Text key={each.id} _hover={{ bg: '#1e90ff', color: 'white' }} h='30px' pt={1} justifyContent={'center'} onClick={() => onClickHandler(each.id, each.name)}>
                                                    {each.name}
                                                </Text>
                                            )
                                        })}
                                    </VStack>
                                </Box>

                            }
                        </VStack>
                        : null
                }
            </Box>
        </>
    );
}

const renderSummaryTitle = (option: string) => {
    switch (option) {
        case 'models':
            return 'La list des models';
        case 'versions':
            return 'La list des versions';
        case 'summary':
            return 'Le résumé';
        default:
            return 'La list des marques';
    }
}

const useOutsideAlerter = (ref: any, setInputFocus: any) => {
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target))
                setInputFocus(false);
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export default SelectVehicle;