import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Center, Flex, Heading, Image, List, ListItem, Progress, SimpleGrid, Stack, Text, UnorderedList } from '@chakra-ui/react'
import React from 'react';
import vendorLogo from '../assets/images/vendor-logo.png'
import locationLogo from '../assets/images/Icon-material-location-blue.png'
import dashboardLogo from '../assets/images/Icon-material-dashboard-blue.png'
import { VendorType } from '../store/slices/vendor-slice'

const VendorCard: React.FC<{ vendor: VendorType }> = (props) => {
    return (
        <Box bg={'white'} boxShadow={'md'} borderRadius={'2xl'} px={3} py={4} border={'2px solid white'} _hover={{ border: '2px solid #1385EB' }}>
            <SimpleGrid templateColumns={{ base: '1fr', lg: '60% 40%', md: '1fr' }} position={'relative'}>
                <Stack >
                    <Accordion allowToggle>

                        <AccordionItem>
                            <h2>
                                <AccordionButton position={'relative'}>
                                    <Center position={'relative'} w={{ base: '30%', xl: '30%', lg: '50%', md: '20%' }} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} h={'70px'}>
                                        <Image h={'70%'} src={vendorLogo} />
                                    </Center>
                                    <Box w={{ base: '70%', xl: '70%', lg: '50%', md: '80%' }}>
                                        <Heading size={'xs'} textAlign={'start'}>
                                            {props.vendor.name}
                                        </Heading>
                                        <Text textAlign={'start'} fontSize={'x-small'}>
                                            Garage multimarques avec plusieurs stations de metro à coté, ouvert de..
                                        </Text>
                                    </Box>

                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box overflowY={'scroll'}>
                                    <Heading size={'sm'}>HORAIRES D’OUVERTURE</Heading>
                                    <List>
                                        <ListItem color={'grey'}>Lundi : 09:00-12:00 / 14:00-17:00</ListItem>
                                        <ListItem color={'grey'}>Mardi : 09:00-12:00 / 14:00-17:00</ListItem>
                                        <ListItem color={'grey'}>Mercredi : 09:00-12:00 / 14:00-17:00</ListItem>
                                        <ListItem color={'grey'}>Jeudi : 09:00-12:00 / 14:00-17:00</ListItem>
                                        <ListItem color={'grey'}>Vendredi : 09:00-12:00 / 14:00-17:00</ListItem>
                                    </List>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Center w={{ base: '30%', xl: '30%', lg: '50%', md: '20%' }} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} h={'70px'}>
                                        <Text color={'#1385EB'}>4.5/5</Text>
                                    </Center>
                                    <Box w={{ base: '70%', xl: '70%', lg: '50%', md: '80%' }}>
                                        <Heading size={'sm'} textAlign={'start'}>
                                            Très bien
                                        </Heading>
                                        <Text textAlign={'start'}>
                                            45 avis
                                        </Text>
                                    </Box>

                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box overflowY={'scroll'}>

                                    <List px={2}>
                                        <ListItem position={'relative'} color={'grey'}>Accueil
                                            <Flex px={4} w={'100%'} justify={'center'} justifyContent={'space-between'}>
                                                {props.vendor.reviews}/5<Progress value={80} w={'40%'} />
                                            </Flex>
                                        </ListItem>
                                        <ListItem position={'relative'} color={'grey'}>Ponctualité
                                            <Flex px={4} w={'100%'} justify={'center'} justifyContent={'space-between'}>
                                                4.5/5<Progress value={80} w={'40%'} />
                                            </Flex>
                                        </ListItem>
                                        <ListItem position={'relative'} color={'grey'}>Conseils
                                            <Flex px={4} w={'100%'} justify={'center'} justifyContent={'space-between'}>
                                                4.5/5<Progress value={80} w={'40%'} />
                                            </Flex>
                                        </ListItem>
                                        <ListItem position={'relative'} color={'grey'}>Délai de restitution
                                            <Flex px={4} w={'100%'} justify={'center'} justifyContent={'space-between'}>
                                                4.5/5<Progress value={80} w={'40%'} />
                                            </Flex>
                                        </ListItem>
                                    </List>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton position={'relative'}>
                                    <Center position={'relative'} w={{ base: '30%', xl: '30%', lg: '50%', md: '20%' }} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} h={'70px'}>
                                        <Image h={'70%'} src={locationLogo} />
                                    </Center>
                                    <Box w={{ base: '70%', xl: '70%', lg: '50%', md: '80%' }}>
                                        <Heading size={'sm'} textAlign={'start'}>

                                        </Heading>
                                        <Text textAlign={'start'}>
                                            {props.vendor.address}
                                        </Text>
                                    </Box>

                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                {/* <Box overflowY={'scroll'} position={'relative'} display={{ base: 'unset', lg: 'none' }}>
                                    <Iframe />
                                </Box> */}
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Center position={'relative'} w={{ base: '30%', xl: '30%', lg: '50%', md: '20%' }} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} h={'70px'}>
                                        <Image h={'70%'} src={dashboardLogo} />
                                    </Center>
                                    <Box w={{ base: '70%', xl: '70%', lg: '50%', md: '80%' }}>
                                        <Heading size={'xs'} textAlign={'start'}>
                                            Voir tous les services proposés
                                        </Heading>
                                        <Text>

                                        </Text>
                                    </Box>

                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box overflowY={'scroll'}>

                                    <UnorderedList>
                                        {props.vendor.services && props.vendor.services.map((service) => {
                                            return (
                                                <ListItem key={service.id}>{service.name}</ListItem>
                                            )
                                        })}
                                    </UnorderedList>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>


                    </Accordion>
                </Stack>
                <Stack h={'100%'} position={'relative'}>
                    <Heading as={'h6'} size={'sm'}>Votre devis en 4 clics</Heading>
                    <Center py={2} h={'80%'}>
                        <List>
                            <ListItem color={'#1385EB'}>Devis immédiat en ligne</ListItem>
                            <ListItem color={'#1385EB'}>Prise de RDV instantané</ListItem>
                            <ListItem color={'#1385EB'}>Avis clients certifiés</ListItem>
                            <ListItem color={'#1385EB'}>Garantie 5 ans pièces & main d’oeuvre</ListItem>
                        </List>
                    </Center>
                    <Box w={'100%'} py={2}>
                        <Button fontSize={'x-small'} w={'100%'} color={'white'} bg={'#1385EB'} _hover={{ bg: '#1385EB' }}>
                            Voir les Tarifs pourMa voiture</Button>
                    </Box>
                </Stack>
            </SimpleGrid>
        </Box>
    )
}

export default VendorCard;