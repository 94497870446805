import { Box, Center, Container, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import DadycarSlider from './DadycarSlider';
import bubbleElemet from '../../../assets/images/Group-1083.png';
import TagIcon from '../../../assets/images/Group-168.png';
import GarageIcon from '../../../assets/images/garage-3.png';
import BestPriceIcone from '../../../assets/images/best-price.png';
import { Search } from '../../../components/_index';

export default function BookingSection() {
    return (
        <Box marginBottom={{ lg: '100px' }} pt={14}>
            <Container maxW={'100%'} w={'1000px'} >
                <Box py={5}>
                    <DadycarSlider />
                </Box>

            </Container>

            <Box position={'absolute'} top={0} zIndex={-1}>
                <Image src={bubbleElemet} objectFit={{lg:'unset',sm:'cover'}} h={{base:'770px',lg:'1200px'}} w={{base:'100vw'}} overflow={'hidden'} />
            </Box>
            <Search text='Commander un dadycar' />


            <Container maxW={'80%'} px={0} py={5} w={{ lg: '1000px' }}>
                <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr 2fr' }} spacing={{base:6,lg:12}}>
                    <Stack bg={'white'} borderRadius={'3xl'} py={7} px={3} boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.203922)'}>
                        <Center>
                            <Box mx={3}>
                                <Image w={'50px'} src={BestPriceIcone} />
                            </Box>
                            <Text fontWeight={'bold'} fontSize={'sm'}>Devis immédiat même prix qu’en atelier</Text>
                        </Center>
                    </Stack>
                    <Stack bg={'white'} borderRadius={'3xl'} py={7} px={3} boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.203922)'}>
                        <Center>
                            <Box mx={3}>
                                <Image w={'50px'} src={GarageIcon} />
                            </Box>
                            <Text fontWeight={'bold'} fontSize={'sm'}>Devis immédiat même prix qu’en atelier</Text>
                        </Center>
                    </Stack>
                    <Stack bg={'white'} borderRadius={'3xl'} py={7} px={3} boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.203922)'}>
                        <Center>
                            <Box mx={3} >
                                <Image w={'50px'} src={TagIcon} />
                            </Box>
                            <Text fontWeight={'bold'} fontSize={'sm'}>Devis immédiat même prix qu’en atelier</Text>
                        </Center>
                    </Stack>
                </SimpleGrid>
            </Container>

        </Box>
    )
}
