import { Box, Container, SimpleGrid, Stack } from '@chakra-ui/react'
import { VendorCard } from '../../../components/_index'
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const VendorsSection: React.FC = () => {
  const vendors = useSelector((state: RootState) => state.vendor.list);
  const Iframe = () => (
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11933.197164110496!2d3.0240914188647965!3d36.736469390712834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128fadfdd859488d%3A0xdd793c72da665b78!2sZoo%20Ben%20Aknoun%20-%20Zone%202!5e0!3m2!1sfr!2sdz!4v1677795682058!5m2!1sfr!2sdz" width="100%" height="100%" style={{ border: "0", borderRadius: '30px' }} loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
  )
  return (
    <>
      <Container maxW={{ lg: '100%' }} w={{ xl: '2000px' }} bg={'#F6FAFC'}>
        <SimpleGrid px={{ xl: 30 }} templateColumns={{ base: '1fr', xl: '6fr 6fr', lg: '60% 40%', md: '1fr' }} spacing={5}>
          <Stack overflowY={'scroll'} w={{ base: '100%', lg: '100%' }} px={6} my={4} h={'1000px'}>
            {vendors && vendors.map((vendor) => {
              return (<VendorCard key={vendor['id']} vendor={vendor} />)
            })}

          </Stack>
          <Stack display={{ base: 'none', lg: 'block' }} w={'100%'} px={6} my={4} position={'relative'}>
            <Box h={'40%'} boxShadow={'md'} borderRadius={'2xl'} w={'90%'} position={'absolute'}>
              <Iframe />
            </Box>
          </Stack>
        </SimpleGrid>
      </Container>
    </>

  )
}

export default VendorsSection;