import { useDispatch } from 'react-redux';
import { modelActions, ModelType } from '../store/slices/model-slice';
import { Model } from '../models/_index';


class ModelViewModel {

    dispatch = useDispatch();

    public getMarkModels = async (markId: number) => {
        const response = await Model.getMarkModels(markId);
        if (response)
            this.dispatch(modelActions.updateList(response.data));
    }

    public selectModel = (model: ModelType) => {
        this.dispatch(modelActions.updateSelected(model));
    }

    public removeSelectedModel = () => {
        this.dispatch(modelActions.removeSelected());
    }
}

export default ModelViewModel;