import { createSlice } from '@reduxjs/toolkit';

type AddressType = {
    value: number;
    label: string;
    city: string;
    postcode: string;
    lat: number;
    lng: number;
};
type SliceState = { list: AddressType[] | null, selected: AddressType | null };

const initialState: SliceState = { list: null, selected: null }

const addressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        updateList(state, action) {
            let array: AddressType[] = [];
            action.payload.map((address: any) => {
                array.push({
                    'value': address['properties']['id'],
                    'label': address['properties']['label'],
                    'city': address['properties']['city'],
                    'postcode': address['properties']['postcode'],
                    'lat': address['geometry']['coordinates'][1],
                    'lng': address['geometry']['coordinates'][0],
                });
            })
            state.list = array;
        },
        resetList(state) {
            state.list = null;
        },
        updateSelected(state, action) {
            state.selected = action.payload;
        },
        removeSelected(state) {
            state.selected = null;
        },
    },
});

export { type AddressType };
export const addressActions = addressSlice.actions;
export default addressSlice.reducer;