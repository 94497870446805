import { createSlice } from '@reduxjs/toolkit';

type ServiceType = {
  id: number;
  name: string;
  description?: string;
};
type SliceState = { list: ServiceType[] | null, selected: ServiceType | null };

const initialState: SliceState = { list: null, selected: null }

const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    updateList(state, action) {
      state.list = action.payload;
    },
    updateSelected(state, action) {
      state.selected = action.payload;
    },
    removeSelected(state) {
      state.selected = null;
    },
  },
});

export { type ServiceType };
export const serviceActions = serviceSlice.actions;
export default serviceSlice.reducer;