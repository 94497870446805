import { Box, Center, Container, Text } from '@chakra-ui/layout';
import { SectionHeader } from '../../../components/_index'
import dotBg from '../../../assets/images/dots-bg.png';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderElement = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <div>
            <Slider {...settings}>
                <Box as='div' p={8}>
                    <Center bg={'#5393EC'} borderRadius={'2xl'} h={'260px'} >
                        <Box p={8}>
                            <Text color={'white'} textAlign={'center'}>“Ancien VTC, je suis passé sur Dadycar ! Je garde
                                une rémunération attractive sans avoir le stress lié à
                                la gestion de mon véhicule.”</Text>
                        </Box>
                    </Center>
                </Box>

                <Box as='div' p={8}>
                    <Center bg={'#5393EC'} borderRadius={'2xl'} h={'260px'} >
                        <Box p={8}>
                            <Text color={'white'} textAlign={'center'}>“Ancien VTC, je suis passé sur Dadycar ! Je garde
                                une rémunération attractive sans avoir le stress lié à
                                la gestion de mon véhicule.”</Text>
                        </Box>
                    </Center>
                </Box>

                <Box as='div' p={8}>
                    <Center bg={'#5393EC'} borderRadius={'2xl'} h={'260px'} >
                        <Box p={8}>
                            <Text color={'white'} textAlign={'center'}>“Ancien VTC, je suis passé sur Dadycar ! Je garde
                                une rémunération attractive sans avoir le stress lié à
                                la gestion de mon véhicule.”</Text>
                        </Box>
                    </Center>
                </Box>

            </Slider>
        </div>
    );
}

export default function SliderSection() {
    return (
        <Box bg={'white'}
            py={5}
            pb={10}
            overflowX={'hidden'}
            backgroundImage={dotBg}
            backgroundSize={{ lg: 'cover' }}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={{ base: 'top', lg: 'center' }} >
            
            <Container mb={20}>
            <SectionHeader title={"Ce que nos Chauffeurs Partenaires disent de Dadycar"} />
                <SliderElement />
            </Container>
        </Box>
    )
}
