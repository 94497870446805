import { Container, Heading, SimpleGrid, Image, Text, Box, Stack, Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import card1 from "../assets/images/istockphoto-538617607-1024x1024.jpg";
import card3 from "../assets/images/oil-change2.jpg";
import card4 from "../assets/images/kit_dembrayage.width-500.jpg";
import card5 from "../assets/images/istockphoto-500003302-612x6122.jpg";
import card6 from "../assets/images/brakepad-522394158-170667a2.jpg";
import card7 from "../assets/images/suspensions-pneumatiques2.jpg";
import card8 from "../assets/images/control-technique2.jpg";


export default function NosPrestation() {
    return (
        <Container maxW={'80%'} w={{ lg: '1000px' }}>
            <Box py={6}>
                <SimpleGrid templateColumns={{ lg: '2fr 2fr 2fr 2fr' }} spacing={8}>
                    <Stack>
                        <Card align='center' borderRadius={'20px'} h={'100%'} bg={{ base: 'transparent', hover: 'white' }} >
                            <CardHeader p={0}>
                                <Image src={card1} borderRadius={'20px 20px 0 0'} />
                            </CardHeader>
                            <CardBody py={1}>
                                <Heading size={'xs'}>DIAGNOSTIC ET RECHERCHE DE PANNE</Heading>
                            </CardBody>
                            <CardFooter py={2}>
                                <Text color={'#1385EB'}>à partir de 45 €</Text>
                            </CardFooter>
                        </Card>
                    </Stack>

                    <Stack>
                        <Card align='center' borderRadius={'20px'} h={'100%'} bg={{ base: 'transparent', hover: 'white' }} >
                            <CardHeader p={0}>
                                <Image src={card1} borderRadius={'20px 20px 0 0'} />
                            </CardHeader>
                            <CardBody py={1}>
                                <Heading size={'xs'}>KIT DE DISTRIBUTION</Heading>
                            </CardBody>
                            <CardFooter py={2}>
                                <Text color={'#1385EB'}>à partir de 165 €</Text>
                            </CardFooter>
                        </Card>
                    </Stack>

                    <Stack>
                        <Card align='center' borderRadius={'20px'} h={'100%'} bg={{ base: 'transparent', hover: 'white' }} >
                            <CardHeader p={0}>
                                <Image src={card3} borderRadius={'20px 20px 0 0'} />
                            </CardHeader>
                            <CardBody py={1}>
                                <Heading size={'xs'}>RÉVISION ET VIDANGE</Heading>
                            </CardBody>
                            <CardFooter py={2}>
                                <Text color={'#1385EB'}>à partir de 69 €</Text>
                            </CardFooter>
                        </Card>
                    </Stack>

                    <Stack>
                        <Card align='center' borderRadius={'20px'} h={'100%'} bg={{ base: 'transparent', hover: 'white' }} >
                            <CardHeader p={0}>
                                <Image src={card4} borderRadius={'20px 20px 0 0'} />
                            </CardHeader>
                            <CardBody py={1}>
                                <Heading size={'xs'}>KIT D’EMBRAYAGE</Heading>
                            </CardBody>
                            <CardFooter py={2}>
                                <Text color={'#1385EB'}>à partir de 499 €</Text>
                            </CardFooter>
                        </Card>
                    </Stack>

                    <Stack>
                        <Card align='center' borderRadius={'20px'} h={'100%'} bg={{ base: 'transparent', hover: 'white' }} >
                            <CardHeader p={0}>
                                <Image src={card5} borderRadius={'20px 20px 0 0'} />
                            </CardHeader>
                            <CardBody py={1}>
                                <Heading size={'xs'}>CARROSSERIE ET VISION</Heading>
                            </CardBody>
                            <CardFooter py={2}>
                                <Text color={'#1385EB'}>à partir de 42 €</Text>
                            </CardFooter>
                        </Card>
                    </Stack>

                    <Stack>
                        <Card align='center' borderRadius={'20px'} h={'100%'} bg={{ base: 'transparent', hover: 'white' }} >
                            <CardHeader p={0}>
                                <Image src={card6} borderRadius={'20px 20px 0 0'} />
                            </CardHeader>
                            <CardBody py={1}>
                                <Heading size={'xs'}>DISOUES ET PLAQUETTES DE FREIN</Heading>
                            </CardBody>
                            <CardFooter py={2}>
                                <Text color={'#1385EB'}>à partir de 39 €</Text>
                            </CardFooter>
                        </Card>
                    </Stack>

                    <Stack>
                        <Card align='center' borderRadius={'20px'} h={'100%'} bg={{ base: 'transparent', hover: 'white' }} >
                            <CardHeader p={0}>
                                <Image src={card7} borderRadius={'20px 20px 0 0'} />
                            </CardHeader>
                            <CardBody py={1}>
                                <Heading size={'xs'}>SUSPENSIONS ET PNEUMATIOUE</Heading>
                            </CardBody>
                            <CardFooter py={2}>
                                <Text color={'#1385EB'}>à partir de 45 €</Text>
                            </CardFooter>
                        </Card>
                    </Stack>

                    <Stack>
                        <Card align='center' borderRadius={'20px'} h={'100%'} bg={{ base: 'transparent', hover: 'white' }} >
                            <CardHeader p={0}>
                                <Image src={card8} borderRadius={'20px 20px 0 0'} />
                            </CardHeader>
                            <CardBody py={1}>
                                <Heading size={'xs'}>CONTRÔLE TECHNIOUE</Heading>
                            </CardBody>
                            <CardFooter py={2}>
                                <Text color={'#1385EB'}>à partir de 45 €</Text>
                            </CardFooter>
                        </Card>
                    </Stack>
                </SimpleGrid>
            </Box>
        </Container>
    )
}
