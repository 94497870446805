import { Route, Routes } from 'react-router-dom'
import {
    HomePage,
    ServicesPage,
    SearchVendorsPage,
    VendorsPage,
    NotFound,
    DriverPage,
    RegisterVendorPage,
} from '../pages/_index'

export default function RouterConfig() {
    return (
        <>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/drivers" element={<DriverPage action={'login'}/>} />
                <Route path="/drivers/register" element={<DriverPage action={'register'}/>}/>
                <Route path="/vendors" element={<VendorsPage />} />
                <Route path="/vendors/search" element={<SearchVendorsPage />} />
                <Route path="/vendor/login" element={<RegisterVendorPage action={"login"}  />} />
                <Route path="/vendor/register" element={<RegisterVendorPage action={"register"}  />} />
                <Route path="/*" element={<NotFound />} />
            </Routes>
        </>
    )
}