import { BookingSection, ApropsSection, ServiceSection, NosPrestationSection, PartenaireSection } from './components/_index';


export default function HomePage() {
  return (
    <>
      <BookingSection />
      <ServiceSection />
      <NosPrestationSection />
      <ApropsSection />
      <PartenaireSection />
    </>
  )
}
