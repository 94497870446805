import { Box, Center, Container, SimpleGrid, Stack, Text } from '@chakra-ui/layout'
import { Progress } from '@chakra-ui/progress'
import { SectionHeader } from '../../../components/_index'

export default function StepsSection() {
    return (
        <Box bg={'#F6FAFC'} py={8}>
            <Container maxW={'80%'} w={{ lg: '1400px' }} mb={{ base: 10, lg: 20 }}>
                <SectionHeader title={"Quelles sont les prochaines étapes ?"} />
                <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr 2fr' }} spancing={10} position={'relative'}>
                    <Progress
                        display={{base:'none',lg:'unset'}}
                        position={'absolute'}
                        top={'45px'}
                        w={'67%'}
                        right={'16%'}
                        size={'xs'}
                        value={100}
                    ></Progress>
                    <Stack p={4}>
                        <Box h={'100%'} position={'relative'} display={{ lg: 'block', base: 'flex' }}>
                            <Center p={4} order={{ base: 2, lg: 1 }}>
                                <Center
                                    borderRadius={'full'}
                                    h={'30px'}
                                    w={'30px'}
                                    bg={'#0F82EA'}
                                    color={'white'}>1</Center>
                            </Center>
                            <Box borderRadius={'2xl'} w={'100%'} bg={'white'} boxShadow={'md'} p={8} h={'100%'}>
                                <Center py={5} >
                                    <Text fontSize={{base:'smaller',lg:'lg'}} color={'#0F82EA'} textAlign={'center'}>
                                        Inscrivez-vous
                                    </Text>
                                </Center>
                                <Center>
                                    <Text textAlign={'center'} fontSize={{base:'smaller',lg:'md'}}>
                                        Une fois toutes vos informations renseignées, notre équipe les vérifie et revient vers vous au plus vite.
                                    </Text>
                                </Center>
                            </Box>
                        </Box>
                    </Stack>

                    <Stack p={4}>
                        <Box h={'100%'} position={'relative'} display={{ lg: 'block', base: 'flex' }}>
                            <Center p={4} order={{ base: 2, lg: 1 }}>
                                <Center
                                    borderRadius={'full'}
                                    h={'30px'}
                                    w={'30px'}
                                    bg={'#0F82EA'}
                                    color={'white'}>2</Center>
                            </Center>
                            <Box borderRadius={'2xl'} w={'100%'} bg={'white'} boxShadow={'md'} p={8} h={'100%'}>
                                <Center py={5} >
                                    <Text fontSize={{base:'smaller',lg:'lg'}} color={'#0F82EA'} textAlign={'center'}>
                                        Remplissez un questionnaire en ligne
                                    </Text>
                                </Center>
                                <Center>
                                    <Text textAlign={'center'} fontSize={{base:'smaller',lg:'md'}}>
                                        Complétez notre questionnaire
                                    </Text>
                                </Center>
                            </Box>
                        </Box>
                    </Stack>

                    <Stack p={4}>
                        <Box h={'100%'} position={'relative'} display={{ lg: 'block', base: 'flex' }}>
                            <Center p={4} order={{ base: 2, lg: 1 }}>
                                <Center
                                    borderRadius={'full'}
                                    h={'30px'}
                                    w={'30px'}
                                    bg={'#0F82EA'}
                                    color={'white'}>3</Center>
                            </Center>
                            <Box borderRadius={'2xl'} w={'100%'} bg={'white'} boxShadow={'md'} p={8} h={'100%'}>
                                <Center py={5} >
                                    <Text fontSize={{base:'smaller',lg:'lg'}} color={'#0F82EA'} textAlign={'center'}>
                                        Commencez vos missions rémunérées !
                                    </Text>
                                </Center>
                                <Center>
                                    <Text textAlign={'center'} fontSize={{base:'smaller',lg:'md'}}>
                                        Une fois votre questionnaire complété, nous vous appellerons par téléphone pour valider votre compte. Vous pourrez alors commencer vos missions rémunérées avec Daycare
                                    </Text>
                                </Center>
                            </Box>
                        </Box>
                    </Stack>
                </SimpleGrid>
            </Container>
        </Box>
    )
}
