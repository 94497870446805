import axios from 'axios';

class HttpClient {

    public static version = 'api/v1';
    public static host = process.env.REACT_APP_HOST!;
    public static config = {
        headers: {
            'Accept': 'applicdation/json',
            'Content-Type': 'applicdation/json'
        }
    };

    public static post = async (apiUrl: string, requestBody: object) => {
        let fullUrl = HttpClient.host + HttpClient.version + apiUrl;
        return await axios.post(fullUrl, requestBody).catch(() => { })
    }

    public static get = async (apiUrl: string, host = HttpClient.host + HttpClient.version) => {
        return await axios.get(host + apiUrl).catch(() => { })
    }
}

export default HttpClient;