import { extendTheme } from '@chakra-ui/react'

const Theme = extendTheme({
  fonts: {
    heading: 'Inter, sans-serif',
    body: 'Inter, sans-serif',
  },
  colors: {
    dadycarBlue: '#5393EC',
    dadycarGradienBluer: 'linear-gradient(90deg, #00B1B7 0%, #0075F5 100%)'
  },
})

export default Theme