import { Box } from '@chakra-ui/react'
import Service from './Service'

export default function ServiceSection() {
    return (
        <Box >
            <Service />
        </Box>
    )
}
