import { Container, Heading, SimpleGrid, Image, Text, Box, Stack, Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import vendor1 from "../../../assets/images/vendor1.png";
import vendor2 from "../../../assets/images/vendor2.png";
import vendor3 from "../../../assets/images/vendor3.png";
import vendor4 from "../../../assets/images/vendor4.png";
import vendor5 from "../../../assets/images/vendor5.png";
import vendor6 from "../../../assets/images/vendor6.png";

const VendorCards = () => {
    const vendors = [
        {
            pic: vendor1,
            vendorName: "Garages Paris",
            vendorDescription: "Trouvez le garage le moins cher à PARIS pour l’entretien de vote auto.",
        },
        {
            pic: vendor2,
            vendorName: "Garages Paris",
            vendorDescription: "Trouvez le garage le moins cher à PARIS pour l’entretien de vote auto.",
        },
        {
            pic: vendor3,
            vendorName: "Garages Paris",
            vendorDescription: "Trouvez le garage le moins cher à PARIS pour l’entretien de vote auto.",
        },
        {
            pic: vendor4,
            vendorName: "Garages Paris",
            vendorDescription: "Trouvez le garage le moins cher à PARIS pour l’entretien de vote auto.",
        },
        {
            pic: vendor5,
            vendorName: "Garages Paris",
            vendorDescription: "Trouvez le garage le moins cher à PARIS pour l’entretien de vote auto.",
        },
        {
            pic: vendor6,
            vendorName: "Garages Paris",
            vendorDescription: "Trouvez le garage le moins cher à PARIS pour l’entretien de vote auto.",
        },
    ]
    return (
        <Container
            maxW={'80%'}
            w={{ lg: '1000px' }}
            py={10}
            px={{lg:32}}
            bg={{lg:'white'}}
            borderRadius={'2xl'}
            boxShadow={{lg:'md'}}>
            <Box py={6}>
                <SimpleGrid templateColumns={{ lg: '2fr 2fr 2fr' }} spacing={8}>
                    {vendors.map((item, idx) => (
                        <Stack key={idx}>
                            <Card align='center' borderRadius={'20px'} h={'100%'} bg={{ base: 'transparent', hover: 'white' }} >
                                <CardHeader p={0}>
                                    <Image src={item.pic} borderRadius={'20px 20px 0 0'} />
                                </CardHeader>
                                <CardBody py={1}>
                                    <Heading size={'xs'}>{item.vendorName}</Heading>
                                </CardBody>
                                <CardFooter py={2}>
                                    <Text color={'#1385EB'}>{item.vendorDescription}</Text>
                                </CardFooter>
                            </Card>
                        </Stack>
                    ))}
                </SimpleGrid>
            </Box>
        </Container>
    )
}
export default VendorCards;