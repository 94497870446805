import { useDispatch } from 'react-redux';
import { vendorActions } from '../store/slices/vendor-slice';
import Vendor from '../models/Vendor';
import { AddressType } from '../store/slices/address-slice';
import { ServiceType } from '../store/slices/service-slice';
import { VersionType } from '../store/slices/version-slice';

class VendorViewModel {

    dispatch = useDispatch();

    public getVendors = async () => {
        const response = await Vendor.getVendors();
        if (response)
            this.dispatch(vendorActions.updateList(response.data.data));
    }

    public getVendorsWithFilter = async (selectedVersion: VersionType, selectedAddress: AddressType, selectedService: ServiceType) => {
        const data = {
            versionId: selectedVersion!.id,
            lat: selectedAddress!.lat,
            lng: selectedAddress!.lng,
            radius: 100,
            serviceId: selectedService!.id,
        };

        const response = await Vendor.getVendorsWithFilter(data);
        if (response)
            this.dispatch(vendorActions.updateList(response.data.data));
    }
}

export default VendorViewModel;