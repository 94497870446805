import { Box, Center, Heading, Image, Link, Text } from "@chakra-ui/react";
import ourServicesBg from '../../../assets/images/tire-bg.png';
import ourServicesBg1 from '../../../assets/images/tire-bg-md.png';
import ourServicesbg2 from '../../../assets/images/tire-bg-sm.png';
import { Search } from "../../../components/_index";

export default function BookingSection() {
    return (
        <Box>
            <Box position={'absolute'} zIndex={-1} >
                <picture>
                    <source media="(min-width:48em)" srcSet={`${ourServicesBg}`} />
                    <source media="(min-width:32em)" srcSet={`${ourServicesBg1}`} />
                    <Image src={ourServicesbg2} width="100vw"
                        backgroundRepeat={'no-repeat'}
                        backgroundSize={'contain'}
                        objectFit={'cover'} />
                </picture>
            </Box>
            <Box pt={32}>
                <Box py={{ lg: 7, sm: 10 }}>
                    <Heading color={'white'} textAlign={'center'}>Nos Prestations</Heading>
                    <Center>
                        <Box>
                            <Text color={'white'} textAlign={'center'}>Nous offrons les meilleurs services aux tarifs compétitifs
                                Voir tous les services
                            </Text>
                            <Text textAlign={'center'} color={'white'}><Link color={'#0F82EA'}>Home</Link>/ Nos prestations</Text>
                        </Box>
                    </Center>
                </Box>

                <Box my={7}>
                    <Search text="Trouver le meilleur garage" />
                </Box>
            </Box>

        </Box>
    )
}
