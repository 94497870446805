import { useDispatch } from 'react-redux';
import { addressActions, AddressType } from '../store/slices/address-slice';
import { Address } from '../models/_index';

class AddressViewModel {

    dispatch = useDispatch();

    public getAddress = async (address: string) => {
        const response = await Address.getAddress(address);
        if (response && response.data['features'].length > 0)
            this.dispatch(addressActions.updateList(response.data['features']));
    }

    public resetAddressesList = () => {
        this.dispatch(addressActions.resetList());
    }

    public selectAddress = (address: AddressType) => {
        this.dispatch(addressActions.updateSelected(address));
    }

    public removeSelectedAddress = () => {
        this.dispatch(addressActions.removeSelected());
    }
}

export default AddressViewModel;