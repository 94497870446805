import { BookingSection, SearchSection, VendorsSection } from './components/_index'

export default function SearchVendorsPage() {

  return (
    <>
      <BookingSection />
      <SearchSection />
      <VendorsSection />
    </>
  )
}
