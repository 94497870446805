import { Image } from '@chakra-ui/image'
import { Box, Center, Container, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/layout'
import debitCardIcon from '../../../assets/images/debit-card.png'
import calendarIcon from '../../../assets/images/calendar-icon.png'
import cashPaymentIcon from '../../../assets/images/cash-payment.png'
import reliabilityIcon from '../../../assets/images/reliability.png'
import trustIcon from '../../../assets/images/trust.png'
import loveIcon from '../../../assets/images/love.png'

export default function AdventageSection() {
    const data = [{
        image: debitCardIcon,
        title: 'Rémunération engageante',
        text: 'Bénéficiez de revenus en travaillant à votre gré.'
    }, {
        image: calendarIcon,
        title: 'Selon votre envie',
        text: "Acceptez les missions que vous souhaitez accomplir"
    }, {
        image: cashPaymentIcon,
        title: "Paiement tous les 15 jours",
        text: "Recevez votre paiement deux fois par mois"
    }, {
        image: reliabilityIcon,
        title: "Multiples missions",
        text: "Profitez d’un éventail de missions (véhicule, distance…)"
    }, {
        image: trustIcon,
        title: "Vous êtes assuré",
        text: "Soyez tranquille, notre partenaire AXA vous protège"
    }, {
        image: loveIcon,
        title: "Une équipe solide",
        text: "Intégrez une merveilleuse équipe soudée  "
    }]
    return (
        <Box bg={'#F6FAFC'} py={8}>
            <Container
                maxW={'80%'}
                px={10}
                py={{base:0,lg:12}}
                w={{ lg: '1000px' }}
                bg={'white'}
                boxShadow={'md'}
                borderRadius={'2xl'}
                >
                <Box>
                    <Center my={0} mx={10} py={10}>
                        <Heading textAlign={'center'}>Les avantages d’être chauffeur Dadycar :</Heading>
                    </Center>
                    <SimpleGrid templateColumns={{base:'2fr',lg:'2fr 2fr 2fr'}} spacing={12}>
                        {data.map((item, idx) => (
                            <Stack key={idx}>
                                <Center>
                                    <Image src={item.image} h={'70px'} />
                                </Center>
                                <Center>
                                    <Text color={'#0F82EA'}>{item.title}</Text>
                                </Center>
                                <Center py={5}>
                                    <Text textAlign={'center'}>{item.text}</Text>
                                </Center>
                            </Stack>
                        ))}
                    </SimpleGrid>
                </Box>
            </Container>
        </Box>

    )
}
