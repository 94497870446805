import { Container, Heading, SimpleGrid, Image, Text, Box, Stack, Card, CardHeader, CardBody, CardFooter, Link } from "@chakra-ui/react";
import vendor1 from "../../../assets/images/vendor-login-1.png";
import vendor2 from "../../../assets/images/vendor-login-2.png";
import vendor3 from "../../../assets/images/vendor-login-3.png";
import {SectionHeader} from "../../../components/_index";

const InformationSection = () => {
    const infos = [
        {
            pic: vendor1,
            vendorName: "Une multitude de garagistes",
            vendorDescription: "nous fait confiance",
        },
        {
            pic: vendor2,
            vendorName: "Des milliers de devis ",
            vendorDescription: "effectués par nos utilisateurs",
        },
        {
            pic: vendor3,
            vendorName: "Une expansion du chiffre d’affaires",
            vendorDescription: "de nos garagistes",
        },
    ]
    return (
        <Box bg={'#F6FAFC'}>
            <SectionHeader title={"Inscription sur Dadycar"}/>
            <Text textAlign={'center'}><Link color={'#0F82EA'}>Home</Link>/ Liste Des Garages</Text>
            <Container
                maxW={'80%'}
                w={{ lg: '1000px' }}
                py={10}
                px={{ lg: 32 }}>
                <Box py={6}>
                    <SimpleGrid templateColumns={{ lg: '2fr 2fr 2fr' }} spacing={8}>
                        {infos.map((item, idx) => (
                            <Stack key={idx}>
                                <Card align='center' borderRadius={'20px'} h={'100%'} bg={{ base: 'transparent', hover: 'white' }} >
                                    <CardHeader p={0}>
                                        <Image src={item.pic} borderRadius={'20px 20px 0 0'} />
                                    </CardHeader>
                                    <CardBody py={1}>
                                        <Heading size={'xs'}>{item.vendorName}</Heading>
                                    </CardBody>
                                    <CardFooter py={2}>
                                        <Text color={'#1385EB'}>{item.vendorDescription}</Text>
                                    </CardFooter>
                                </Card>
                            </Stack>
                        ))}
                    </SimpleGrid>
                </Box>
            </Container>
        </Box>
    )
}
export default InformationSection;