import { Box, Button, Center, Container, Heading, Image, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import leftSvg from '../assets/images/Path-60.png'
import rightSvg from '../assets/images/Path-59.png'

export default function BecomePartener(props) {
    return (
        <Container
            borderRadius={{ lg: '30px', base: '30px' }}
            bg={'white'}
            maxW={'80%'}
            px={{ lg: 0, base: 3 }}
            py={{ base: 3, lg: 0 }}
            w={{ lg: '1050px' }}
            position={'relative'}
            my={'10'}
            boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.14902)'}>
            <SimpleGrid templateColumns={{ base: '1fr', lg: '1fr 5fr 3fr' }}>
                <Stack>
                    <Box display={{ lg: 'block', base: 'none' }}>
                        <Image src={leftSvg} height={'150px'} maxH={'100%'} />
                    </Box>
                </Stack>

                <Center display={'flex'} justifyContent={{ base: 'center', lg: 'start' }} align={'start'}>
                    <Box>
                        <Heading textAlign={{ base: 'center', lg: 'start' }}
                            my={3} as={'h4'} size={{ base: 'md', lg: 'lg' }}>{props.title ? props.title : 'Devenez Garage Partenaire'} </Heading>
                        <Text fontSize={'lg'}>{props.text ? props.text : 'Boostez votre visibilité et générez du CA'}</Text>
                    </Box>
                </Center>

                <Stack zIndex={1} my={{ base: '0', lg: '8' }} >
                    <Box w={'100%'} py={3}>
                        <Button
                            transition={'ease-in'} w={{ base: '100%', lg: 'initial' }} color={'white'}
                            borderRadius={'xl'}
                            fontSize={'sm'}
                            _hover={{ bg: '#5393EC' }}
                            bg={{
                                base: 'linear-gradient(90deg, #00B1B7 0%, #0075F5 100%)',
                                lg: '#5393EC'
                            }}>JE M’INSCRIS</Button>
                    </Box>

                </Stack>


                <Box zIndex={0} position={'absolute'} right={'0'} display={{ base: 'none', lg: 'block' }}>
                    <Image src={rightSvg} height={'150px'} />
                </Box>
            </SimpleGrid>
        </Container>
    )
}
