import { Box, Center, Heading, Image, Link, Text } from "@chakra-ui/react";
import vendorsBg from '../../../assets/images/vendors-bg.png';
import vendorsBg1 from '../../../assets/images/vendors-bg-md.png';
import vendorsbg2 from '../../../assets/images/vendors-bg-sm.png';
import { Search } from "../../../components/_index";

export default function BookingSection() {
    return (
        <Box>
            <Box position={'absolute'} zIndex={-1} >
                <picture>
                    <source media="(min-width:48em)" srcSet={`${vendorsBg}`} />
                    <source media="(min-width:31em)" srcSet={`${vendorsBg1}`} />
                    <Image src={vendorsbg2} width="100vw"
                        backgroundRepeat={'no-repeat'}
                        backgroundSize={'contain'}
                        objectFit={'cover'} />
                </picture>
            </Box>
            <Box pt={32}>
                <Box py={{ lg: 7, sm: 10 }}>
                    <Heading color={'white'} textAlign={'center'}>Liste Des Garages</Heading>
                    <Center>
                        <Box>
                            <Text color={'white'} textAlign={'center'}>Nous offrons les meilleurs services aux tarifs compétitifs Voir tous les services</Text>
                            <Text textAlign={'center'} color={'white'}><Link color={'#0F82EA'}>Home</Link>/ Liste Des Garages</Text>
                        </Box>
                    </Center>
                </Box>

                <Box my={7}>
                    <Search text='Trouver le meilleur garage' />
                </Box>
            </Box>

        </Box>
    )
}
