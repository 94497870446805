import { useDispatch } from 'react-redux';
import { markActions, MarkType } from '../store/slices/mark-slice';
import { Mark } from '../models/_index';

class MarkViewModel {

    dispatch = useDispatch();

    public getMarks = async () => {
        const response = await Mark.getMarks();
        if (response)
            this.dispatch(markActions.updateList(response.data));
    }

    public selectMark = (mark: MarkType) => {
        this.dispatch(markActions.updateSelected(mark));
    }

    public removeSelectedMark = () => {
        this.dispatch(markActions.removeSelected());
    }
}

export default MarkViewModel;