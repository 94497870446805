import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Button, ButtonGroup, Heading, IconButton, Image, Text } from '@chakra-ui/react';
import sliderPic from '../../../assets/images/woman-smiling.png';
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";

function NextArrow(props) {
  const { onClick } = props
  return (
    <Box position={'absolute'} left={'-50px'} bottom={'110px'} display={{ base: 'none', lg: 'block' }}>
      <Button onClick={onClick}
        borderRadius={'full'} bg={'white'}><i className="fa-solid fa-angle-right"></i></Button>
    </Box>
  )

}
function PrevArrow(props) {
  const { onClick } = props
  return (
    <Box position={'absolute'} left={'-50px'} bottom={'56px'} display={{ base: 'none', lg: 'block' }}>
      <Button onClick={onClick}
        borderRadius={'full'} bg={'white'}>
        <i className="fa-solid fa-angle-left"></i>
      </Button>
    </Box>
  )

}

export default function DadycarSlider() {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
      <Box as='div' my={5} marginLeft={{ base: 0, lg: '80px' }}>
        <Slider {...settings}>
          <Box px={3} position={'relative'}>
            <Box position={'absolute'} top={{ base: 4, lg: 14 }} right={{ base: '30%', md: 12, lg: 12 }}>
              <ButtonGroup>
                <IconButton size={{ base: 'xs', lg: 'md' }} borderRadius={'full'} bg={'#ffffff52'} color={'white'}><FaFacebookF /></IconButton>
                <IconButton size={{ base: 'xs', lg: 'md' }} borderRadius={'full'} bg={'#ffffff52'} color={'white'}><FaTwitter /></IconButton>
                <IconButton size={{ base: 'xs', lg: 'md' }} borderRadius={'full'} bg={'#ffffff52'} color={'white'}><BsInstagram /></IconButton>
              </ButtonGroup>
            </Box>
            <Box position={'absolute'}
              px={6} display={{ base: 'none', md: 'unset', lg: 'unset' }} top={'40%'}>
              <Heading as={'h1'} color={'white'}>Comparez le prix des meilleurs garages et Dadycar se charge du reste.</Heading>
              <Text color={'white'}>Entretenir sa voiture n’a jamais été aussi simple</Text>
            </Box>
            <Image src={sliderPic} />
          </Box>


          <Box px={3} position={'relative'}>
            <Box position={'absolute'} top={{ base: 4, lg: 14 }} right={{ base: '30%', md: 12, lg: 12 }}>
              <ButtonGroup>
                <IconButton size={{ base: 'xs', lg: 'md' }} borderRadius={'full'} bg={'#ffffff52'} color={'white'}><FaFacebookF /></IconButton>
                <IconButton size={{ base: 'xs', lg: 'md' }} borderRadius={'full'} bg={'#ffffff52'} color={'white'}><FaTwitter /></IconButton>
                <IconButton size={{ base: 'xs', lg: 'md' }} borderRadius={'full'} bg={'#ffffff52'} color={'white'}><BsInstagram /></IconButton>
              </ButtonGroup>
            </Box>
            <Box position={'absolute'}
              px={6} display={{ base: 'none', md: 'unset', lg: 'unset' }} top={'40%'}>
              <Heading as={'h1'} color={'white'}>Comparez le prix des meilleurs garages et Dadycar se charge du reste.</Heading>
              <Text color={'white'}>Entretenir sa voiture n’a jamais été aussi simple</Text>
            </Box>
            <Image src={sliderPic} />
          </Box>

          <Box px={3} position={'relative'}>
            <Box position={'absolute'} top={{ base: 4, lg: 14 }} right={{ base: '30%', md: 12, lg: 12 }}>
              <ButtonGroup>
                <IconButton size={{ base: 'xs', lg: 'md' }} borderRadius={'full'} bg={'#ffffff52'} color={'white'}><FaFacebookF /></IconButton>
                <IconButton size={{ base: 'xs', lg: 'md' }} borderRadius={'full'} bg={'#ffffff52'} color={'white'}><FaTwitter /></IconButton>
                <IconButton size={{ base: 'xs', lg: 'md' }} borderRadius={'full'} bg={'#ffffff52'} color={'white'}><BsInstagram /></IconButton>
              </ButtonGroup>
            </Box>
            <Box position={'absolute'}
              px={6} display={{ base: 'none', md: 'unset', lg: 'unset' }} top={'40%'}>
              <Heading as={'h1'} color={'white'}>Comparez le prix des meilleurs garages et Dadycar se charge du reste.</Heading>
              <Text color={'white'}>Entretenir sa voiture n’a jamais été aussi simple</Text>
            </Box>
            <Image src={sliderPic} />
          </Box>
        </Slider>
      </Box></>
  );
}
