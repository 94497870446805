import { Button } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useState, useEffect } from "react";
import VendorViewModel from "../../../view-models/VendorViewModel";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const SubmitButton: React.FC<{ text: string }> = (props) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const selectedVersion = useSelector((state: RootState) => state.version.selected);
    const selectedAddress = useSelector((state: RootState) => state.address.selected);
    const selectedService = useSelector((state: RootState) => state.service.selected);
    const vendorViewModel = new VendorViewModel();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedVersion && selectedAddress && selectedService)
            setIsDisabled(false);
        else
            setIsDisabled(true);
    }, [selectedVersion, selectedAddress, selectedService]);

    const HandleSubmit = () => {
        if (selectedVersion && selectedAddress && selectedService)
            vendorViewModel.getVendorsWithFilter(selectedVersion, selectedAddress, selectedService);

        if (location.pathname !== '/vendors/search') {
            navigate('/vendors/search', { replace: true });
        }
    }

    return (
        <>
            <Button
                bg={'#252525'}
                color={'white'}
                borderRadius={'2xl'}
                w={'100%'}
                fontSize={'sm'}
                h={'100%'} px={4} py={3}
                _hover={{ bg: '#5a5959' }}
                onClick={HandleSubmit}
                isDisabled={isDisabled}
            >
                {props.text}
            </Button>
        </>
    );
}

export default SubmitButton;