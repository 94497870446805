import BecomePartener from '../../components/BecomePartener';
import { BookingSection, NosPrestationSection } from './components/_index';


export default function ServicesPage() {
  return (
    <>
      <BookingSection />
      <NosPrestationSection />
      <BecomePartener />
    </>
  )
}