import { Helmet } from "react-helmet"
import { Heading, Image, Box, Text  } from "@chakra-ui/react"
import notFound from '../../assets/images/not_found.png'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <>
            <Helmet>
                <title>404 | Dadycar</title>
            </Helmet>
            <Box display={{ base:'none', lg:'block' }} background='#0F82EA' height='20'></Box>
            <Box boxSize='xl' mx="auto" my={'8'} >
                <Image src={notFound}/>
            </Box>
            <Heading as="h1" size="2xl" mt="-48" textAlign={"center"} fontFamily={"poppins"} fontWeight={"medium"}>Page introuvable</Heading>
            <Text textAlign={"center"} fontSize={"xl"} color="#0F82EA" mt="4" pb={'16'}  textTransform={"capitalize"} fontWeight={"medium"}>
                <Link to="/">Retourner</Link>
            </Text>
        </>
    )
}

export default NotFound;