import { Box, Image, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RootState } from "../../../store/index";
import Select, { InputActionMeta } from 'react-select';
import positionIcon from '../../../assets/images/Icon-material-location-on.png';
import AddressViewModel from '../../../view-models/AddressViewModel';

const SearchAddressAutocomplete = () => {
  const addressViewModel = new AddressViewModel();
  const getAddress = addressViewModel.getAddress;
  const resetAddressesList = addressViewModel.resetAddressesList;
  const selectAddress = addressViewModel.selectAddress;
  const removeSelectedAddress = addressViewModel.removeSelectedAddress;

  const selectedAddress = useSelector((state: RootState) => state.address.selected);
  let addresses = useSelector((state: RootState) => state.address.list);
  addresses = addresses ? addresses : [];

  const onChangeHandler = (selected: any, actionMeta: any) => {
    if (actionMeta.action === 'select-option')
      selectAddress(selected);
    else if (actionMeta.action === 'clear') {
      removeSelectedAddress();
      resetAddressesList();
    }
  };

  const onInputChangeHandler = (inputValue: string, { action }: InputActionMeta) => {
    if (action === 'input-change' && inputValue.length > 2)
      getAddress(inputValue)
  };

  return (
    <>
      <Stack direction={'row'} align={'center'} bg={'#E8EAF1'} m={'2'} borderRadius={'2xl'} pl={4} pr={1} py={1}>
        <Image h={'20px'} src={positionIcon} />
        <Box w={'100%'}>
          <Select
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            name="address"
            placeholder="Votre address"
            options={addresses}
            onChange={onChangeHandler}
            onInputChange={onInputChangeHandler}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? '#1A202' : '#E8EAF1',
                border: '1px',
                backgroundColor: '#E8EAF1',
              }),
            }}
            value={selectedAddress}
          />
        </Box>
      </Stack>
    </>
  );
};

export default SearchAddressAutocomplete;
