import { Box } from '@chakra-ui/react';
import { BecomePartener, NosPrestation, SectionHeader } from '../../../components/_index';
import dotBg2 from '../../../assets/images/dots-bg-2.png';

export default function NosPrestationSection() {
    return (
        <Box bg={'white'}
            py={2}
            backgroundImage={dotBg2}
            backgroundSize={{ lg: 'cover' }}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={{ base: 'top', lg: 'center' }} >
            <SectionHeader sectionName={'Nos Prestations'} title={'Nos prestations d’entretien et de réparations'} />
            <NosPrestation />
            <BecomePartener />
        </Box>
    )
}
