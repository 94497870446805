import { Box, Center, Container, Image, List, ListItem, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import stats from '../../../assets/images/Path-415.png'
import AproposPic from '../../../assets/images/Group-577.png'
import { SectionHeader } from "../../../components/_index";

export default function AproposSection() {
    return (
        <Box bg={'#F6FAFC'} py={5}>
            <Container maxW={'80%'} w={{ lg: '1400px' }}>
                <SectionHeader sectionName={'A propos'} title={"Nous sommes une équipe d’experts du web visant à changer le futur de la prestation automobile."} />
                <Center py={7}>
                    <Image src={stats} width={'100px'} />
                </Center>
                <Box py={5}>
                    <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr' }} spacing={6}>
                        <Stack >
                            <Center>
                                <Box>
                                    <Text>
                                        Dadycar est la lère plateforme qui permet de commander en ligne des prestations d’entretien automobile sans avoir à se mobiliser ni à se déplacer. En indiquant vote numéro d’immatriculation, votre
                                        localisation et le service souhaité, vous aurez la possibilité de comparer les offres des meilleurs garages et de réserver en ligne votre rendez-vous.
                                    </Text>
                                    <List spacing={3} py={3}>
                                        <ListItem fontWeight={'bold'}>
                                            TECHNICIENS EXPÉRIMENTÉS
                                        </ListItem>
                                        <ListItem fontWeight={'bold'}>
                                            PRESTATION DE QUALITÉ
                                        </ListItem>
                                        <ListItem fontWeight={'bold'}>
                                            GARANTIE 1 AN
                                        </ListItem>
                                    </List>
                                </Box>

                            </Center>

                        </Stack>
                        <Stack>
                            <Center>
                                <Image src={AproposPic} />
                            </Center>
                        </Stack>
                    </SimpleGrid>
                </Box>
            </Container>
        </Box>
    )
}
